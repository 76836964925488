// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@400;500;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Sans+Serif:wght@400;500;600;700&display=swap");

.translateX-15 {
	transform: translateX(15px) !important;
}

////////////////////////////////
.swiper {
	height: 100%;
}
.hideScrollBarDesktop::-webkit-scrollbar {
	display: none;
}

.scrollbarCustomer::-webkit-scrollbar {
	width: 5px;
	height: 8px;
	cursor: pointer;
}

.scrollbarCustomer::-webkit-scrollbar-thumb {
	background: var(--gray-3);
	border-radius: 5px;
	height: 4px;
}

@media screen and (max-width: 768px) {
	.hideScrollBarMobile::-webkit-scrollbar {
		display: none;
	}
}

/* React Transaction Group */
.modal-content-enter {
	opacity: 0;
	transform: translateY(-200px);
}
.modal-content-enter-active:placeholder-shown {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 0.3s, transform 0.4s;
}
.modal-content-exit {
	opacity: 1;
	transform: translateY(0);
}
.modal-content-exit-active {
	opacity: 0;
	transform: translateY(-200px);
	transition: opacity 0.3s, transform 0.4s;
}

.modal-bg-enter {
	opacity: 0;
}
.modal-bg-enter-active {
	opacity: 1;
	transition: opacity 0.4s ease-in;
}
.modal-bg-exit {
	opacity: 1;
}
.modal-bg-exit-active {
	opacity: 0;
	transition: opacity 0.4s ease-out;
}

.modal-wishlist-enter {
	opacity: 0;
	transform: translateY(200px);
}
.modal-wishlist-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 0.3s, transform 0.4s;
}
.modal-wishlist-exit {
	opacity: 1;
	transform: translateY(0);
}
.modal-wishlist-exit-active {
	opacity: 0;
	transform: translateY(200px);
	transition: opacity 0.3s, transform 0.4s;
}

/* Pagination */
.rc-pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
}
.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-next,
.rc-pagination-jump-prev {
	font-size: var(--fs-default);
	color: var(--gray-main);
	font-weight: bold;
	cursor: pointer;
	min-width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
}
.rc-pagination-item-active {
	color: var(--red-main);
}
.rc-pagination-next.rc-pagination-disabled,
.rc-pagination-prev.rc-pagination-disabled {
	display: none;
}

/* Price Range */
.shopproductfilter__wrapper input[type="range"]::-webkit-slider-thumb {
	pointer-events: auto;
	width: 18px;
	height: 18px;
	border-radius: 27px;
	border: 1px solid #aaa;
	-webkit-appearance: none;
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 1) 0,
		rgba(220, 220, 220, 1) 20%,
		rgba(255, 255, 255, 1) 100%
	);

	/* @apply w-6 h-6 appearance-none pointer-events-auto; */
}

// .collection_wrapper .swiper-slide:nth-child(1) {
// 	@media screen and (max-width: 768px) {
// 		margin-left: 15px;
// 	}
// }
//BannerV2.tsx
.bannerhome__wrapper {
	//   position: relative;
	//   width: 100%;
	//   height: 100%;
	//   z-index: 1;
	//   display: flex;
	//   box-sizing: content-box;
	//   transition-property: transform;
	.swiper-pagination {
		height: 10px;
		left: 0;
		width: 100%;
		bottom: 8px;
		position: absolute;
		right: 0;
		text-align: center;
		// transition: 0.3s opacity;
		// transform: translate3d(0, 0, 0);
		z-index: 10;
		display: flex;
		justify-content: center;
		gap: 4px;
	}

	.swiper-pagination-bullet {
		transition: all 0.2s ease-in;
		cursor: pointer;
		display: inline-block;
		width: 8px;
		height: 3px;
		background: #d8d7d8;
		border-radius: 4px;
		opacity: 1;
	}

	.swiper-pagination .swiper-pagination-bullet-active {
		width: 25px !important;
		background: #fff;
		opacity: 1;
	}
}

.card_images {
	//   position: relative;
	//   width: 100%;
	//   height: 100%;
	//   z-index: 1;
	//   display: flex;
	//   box-sizing: content-box;
	//   transition-property: transform;
	.swiper-pagination {
		height: 10px;
		left: 0;
		width: 100%;
		bottom: -8px;
		position: absolute;
		right: 0;
		text-align: center;
		// transition: 0.3s opacity;
		// transform: translate3d(0, 0, 0);
		z-index: 10;
		display: flex;
		justify-content: center;
		gap: 2px;
	}

	.swiper-pagination-bullet {
		transition: all 0.2s ease-in;
		cursor: pointer;
		display: inline-block;
		width: 3px;
		height: 3px;
		background: #d8d7d8;
		border-radius: 4px;
		opacity: 1;
		flex-shrink: 0;
		margin: 0 !important;
	}

	.swiper-pagination .swiper-pagination-bullet-active {
		width: 16px !important;
		background: var(--gray-4);
		opacity: 1;
	}
	.swiper-pagination-horizontal {
		bottom: -4px !important;
	}
}

// comment
.comment_input label::after {
	display: none !important;
}

.ant-modal-body p {
	font-weight: 600;
	margin-bottom: 12px;
}

.swiper-button-prev,
.swiper-button-next {
	z-index: 1;
}

.slide-image-mobile {
	.swiper-pagination {
		left: 0;
		width: 100%;
		height: 30px;
		bottom: 0px !important;
		position: absolute;
		right: 0;
		text-align: center;
		z-index: 10;
		display: flex;
		justify-content: center;
		gap: 3px;
		opacity: 0.5;
		padding-top: 44px;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.1) 0%,
			rgba(0, 0, 0, 0.1) 80%
		);
	}

	.swiper-pagination-bullet {
		height: 4px;
		display: inline-block;
		width: 4px;
		background: #fff;
		border-radius: 9999px;
		opacity: 1;
		margin: 0 !important;
	}

	.swiper-pagination .swiper-pagination-bullet-active {
		width: 14px !important;
		background: #fff;
		opacity: 1;
		border-radius: 5px;
	}
}

// collapse
.collapse-enter {
	height: 0;
	overflow: hidden;
}

.collapse-enter-active {
	height: auto;
	transition: height 300ms ease-in-out;
}

.collapse-exit {
	height: auto;
}

.collapse-exit-active {
	height: 0;
	overflow: hidden;
	transition: height 300ms ease-in-out;
}

//nmagazine-collection
#nmagazine-collection {
	.swiper-slide {
		margin: 0 !important;
	}
}

//nmagazine banner product
#bannerNMagazineProduct {
	position: relative;

	.swiper-pagination {
		transform: translate3d(-20%, 0, 0) !important;
		bottom: 24px !important;

		@media screen and (max-width: 768px) {
			transform: translate3d(0, 0, 0) !important;
			top: 330px !important;
		}
	}

	.swiper-pagination-bullet {
		transition: all 0.2s ease-in;
		cursor: pointer;
		display: inline-block;
		width: 8px;
		height: 3px;
		background: #d8d7d8;
		border-radius: 4px;
		opacity: 1;
	}

	.swiper-pagination .swiper-pagination-bullet-active {
		width: 25px !important;
		background: #fff;
		opacity: 1;
	}
}

// #select {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8.90625 19.9181L15.4263 13.3981C16.1963 12.6281 16.1963 11.3681 15.4263 10.5981L8.90625 4.07812' stroke='black' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round'/></svg>");
//   background-repeat: no-repeat;
//   background-position-x: 100%;
//   background-position-y: 0;
// }

.rdp [aria-hidden="true"] {
	@apply hidden;
}

.rdp-vhidden {
	@apply hidden;
}

.swiper-button-disabled {
	cursor: not-allowed;
	opacity: 0.4;
}

////////////////////////////////
.ql-editor {
	h1 {
		font-size: var(--fs-default);
		color: var(--gray-main);
	}
}

@media screen and (max-width: 768px) {
	#image_product {
		flex: 0 0 100px !important;
	}
}
.brand_home {
	.brand_item {
		border-right: 1px solid var(--gray-2);
		border-bottom: 1px solid var(--gray-2);
	}
	.brand_item:nth-child(1),
	.brand_item:nth-child(2),
	.brand_item:nth-child(3),
	.brand_item:nth-child(4) {
		border-top: 1px solid var(--gray-2);
	}

	.brand_item:nth-child(4n + 1) {
		border-left: 1px solid var(--gray-2);
	}
}

.pro_body__card {
	transition: all 0.3s linear;
	height: 3px;
	width: 100%;
	background: #b1d1ff;
	border-radius: 3px;
	position: relative;
	z-index: 1;

	&::after {
		content: "";
		position: absolute;
		transition: all 0.3s linear;

		inset: 0;
		z-index: 2;
		background-color: var(--blue-5);
		width: var(--percent);
	}
}

// Product Card
