/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sans+Serif:wght@400;500;600;700&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 222.2 84% 4.9%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;

		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;

		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 212.7 26.8% 83.9%;
	}
	html {
		--red-main: #d72229;
		--gray-main: #3a393a;
		--white-main: #fff;
		--yellow-main: #fdba4d;
		--yellow-2: #fbee86;
		--yellow-6: #e48900;
		--pink-main: #f7d2d9;
		--blue-main: #004b8f;
		--blue-2: #96c3f2;

		--blue-3: #d5e6fb;
		--blue-4: #2f80ed;
		--blue-5: #1876ff;
		--green-5: #23ce68;

		--gray-4: #898889;
		--gray-3: #d8d7d8;
		--gray-2: #f7f7f7;
		--gray-1: #f6e9e9;
		--black: #000;
		--white: #fff;
		--fs-h1: 30px;
		--fs-h2: 24px;
		--fs-h3: 20px;
		--fs-h4: 18px;
		--fs-h5: 16px;
		--fs-default: 14px;
		--fs-sm: 12px;
		--fs-xs: 10px;
	}

	body {
		pointer-events: auto !important;
	}

	/* h1 {
		font-size: var(--fs-h1);
	}
	h2 {
		font-size: var(--fs-h2);
	}
	h3 {
		font-size: var(--fs-h3);
	}
	h4 {
		font-size: var(--fs-h4);
	}
	h5 {
		font-size: var(--fs-h5);
	} */
}

@layer utilities {
	@variants responsive {
		/* Hide scrollbar for Chrome, Safari and Opera */
		.no-scrollbar::-webkit-scrollbar {
			display: none;
		}

		/* Hide scrollbar for IE, Edge and Firefox */
		.no-scrollbar {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
	}
}
@media (prefers-color-scheme: dark) {
	:root {
		--foreground-rgb: 255, 255, 255;
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;
	}
}
/* html {
	font-family: "Inter", "Noto Serif Display", sans-serif;
} */

body {
	font-family: Inter, sans-serif !important;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

img,
picture,
svg,
video {
	display: block;
	max-width: 100%;
}
input,
select,
textarea {
	background-color: transparent;
	outline: none;
}
button {
	cursor: pointer;
	background-color: transparent;
	outline: none;
	/* border: 0; */
}
body {
	min-height: 100vh;
	font-size: 14px;
	color: var(--gray-main);
}

.container {
	margin-left: auto;
	margin-right: auto;
}

.section_container {
	margin-left: auto;
	margin-right: auto;
}
@media screen and (min-width: 768px) {
	.container,
	.section_container {
		width: 750px !important;
	}
}

@media screen and (min-width: 992px) {
	.container,
	.section_container {
		width: 970px !important;
	}
}
@media screen and (min-width: 1200px) {
	.container,
	.section_container {
		width: 1220px !important;
	}
}

@media (min-width: 414px) {
	.container {
		max-width: unset !important;
	}
}
.swiper-scrollbar {
	display: none;
}

.minheightBody {
	min-height: calc(100vh - 702px);
}

@media screen and (max-width: 768px) {
	.minheightBody {
		min-height: calc(50vh);
	}
}

/* @layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
} */

.swiper {
	height: 100%;
}

.swiper-slide,
.swiper-wrapper {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}

.ant-modal-wrap,
.ant-modal-mask {
	z-index: 1002 !important;
}

@media screen and (max-width: 768px) {
	.des_product_detail {
		overflow: hidden;
		height: 140px;
	}
}
.view_more_des {
	position: relative;
	width: 100%;
	height: 60px;
	display: flex;
	text-align: left;
	margin: -40px auto 24px;
	padding: 35px 0 0;
	font-size: 12px;
	text-decoration: unset;
	color: #004b8f;
	justify-content: end;
	align-items: center;
}

.view_more_shadow {
	background: linear-gradient(to bottom, #fff0 0%, #fff 40%);
}

.tabs {
	-webkit-overflow-scrolling: touch;
}

.event-popup {
	padding-bottom: 46.84%;
	background: var(--bgdesk);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
@media screen and (max-width: 768px) {
	.event-popup {
		padding-bottom: 100%;
		background: var(--bgmobile);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

.ant-modal-wrap {
	z-index: 999991 !important;
}

.ant-image-preview-mask {
	z-index: 9999992 !important;
}

.ant-image-preview-wrap {
	z-index: 9999993 !important;
}

.ant-popover {
	z-index: 9999994 !important;
}

#next-id-galley_list svg {
	width: 24px !important;
	height: 24px !important;
}
#pre-id-galley_list svg {
	width: 24px !important;
	height: 24px !important;
}

.scrollableDiv_review .ant-modal-content .ant-modal-body {
	overflow: unset !important;
}
.hide-spin-buttons::-webkit-outer-spin-button,
.hide-spin-buttons::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Đối với Firefox */
.hide-spin-buttons[type="number"] {
	-moz-appearance: textfield;
}

.hide-spin-buttons:focus::-webkit-outer-spin-button,
.hide-spin-buttons:focus::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.hide-spin-buttons:focus {
	-moz-appearance: textfield; /* Firefox, khi focus */
	appearance: textfield; /* Khi focus cho các trình duyệt hỗ trợ appearance */
}
